// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.restore-by-phone__label_oQNnh{font-size:14px;font-weight:400;line-height:24px;letter-spacing:.25px;margin:16px 0 20px;color:var(--TextPrimary);text-align:left}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"restore-by-phone__label": `restore-by-phone__label_oQNnh`
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.udrd--buttons_rFopA{display:flex;flex-direction:column}.udrd--support_YhWsN{font-size:14px;font-weight:400;line-height:16px;letter-spacing:.25px;padding:14px 0;margin-top:20px;color:var(--TextSecondary);-webkit-text-decoration:underline;text-decoration:underline;cursor:pointer}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"udrd--buttons": `udrd--buttons_rFopA`,
	"udrd--support": `udrd--support_YhWsN`
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.udrc_hXjW2{padding:0 16px}.udrc--loader_Vog2g{margin:20px 0 32px}.udrc--form_Xat2X{margin:16px 0}.udrc--declined_nBLs2{margin:20px 0 32px}html.app__layout--desktop .udrc_hXjW2{padding:0 32px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"udrc": `udrc_hXjW2`,
	"udrc--loader": `udrc--loader_Vog2g`,
	"udrc--form": `udrc--form_Xat2X`,
	"udrc--declined": `udrc--declined_nBLs2`
};
export default ___CSS_LOADER_EXPORT___;
